import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { AlertController } from '@ionic/angular';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {LocalNotifications } from '@capacitor/local-notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  pushNotificationToken: any; // I save the token to database because i need it in my backend code.

  constructor(
    private alertController: AlertController,
    // private iab: InAppBrowser
  ) {

  }

  async initNotifs() {
    Device.getInfo().then(async res => {

        if (res.platform !== 'web') {

          this.registerLocalNotif();

          let notifs = await LocalNotifications.getPending()
          if (notifs.notifications.length < 1) this.scheduleNotif()
        }
      
      // else {
      //   // this.registerPush()
      // }
    });
  }

  async registerLocalNotif(){
    let status = await LocalNotifications.checkPermissions();

    if (status?.display != 'granted'){
      let reqStatus = await LocalNotifications.requestPermissions()

      if (reqStatus?.display === 'granted') {
        console.log('Notification permission granted - request');
        return true;
      }
      else {
        console.log('Notification permission denied');
      }
      return false;
    }
  }

  async scheduleNotif(){
    let notifs = await LocalNotifications.schedule({
      notifications: [
      {
        id: 1,
        title: `Record today's connections`,
        body: "Keep your circles healthy and active", //make this be "X contacts are falling out of touch"
        schedule: { on: { hour: 21, minute: 1}, allowWhileIdle: true, every: 'day' }
        }
    ]})

    console.log(notifs)
  }

  registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        console.log('My token: ' + JSON.stringify(token));
        this.pushNotificationToken = JSON.stringify(token.value);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
 
        const data = notification;

        const alert = await this.alertController.create({
          header: JSON.stringify(notification.title).substring(1, JSON.stringify(notification.title).length - 1),
          message: JSON.stringify(notification.body).substring(1, JSON.stringify(notification.body).length - 1),
          buttons: [
            {
              text: 'OK',
              handler: () => {
                if (data.data.url != '' && data.data.url != undefined) {
                  // const browser = this.iab.create(data.data.url, '_blank', { location: 'no' });
                }
              }

            }
          ]
        });
        await alert.present();
      }
    );



    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));

        if (data.url) {
          if (data.url != '' && data.url != undefined) {
            // const browser = this.iab.create(data.url, '_blank', { location: 'no' });
          }
        }
  
      }
    );
  }

  resetBadgeCount() {
    Device.getInfo().then(res => {
      if (res.platform !== 'web') {
        PushNotifications.removeAllDeliveredNotifications();
      }
    });
  }
}